.vertical_side_panel {
  position: absolute;
  z-index: 1;

  top: 0;
  right: 0;
  width: 320px;
  padding: 0 20px;
  background-color: rgb(104, 104, 104);
  color: white;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);

  transform: translateY(-100%);
  transition: transform 0.15s ease-out;
}

.side_panel_height {
  transform: translateY(0%);
  transition: transform 0.25s ease-in;
}

.close_button_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 18px;
  float: right;
  z-index: 1;
}
.close_button {
  position: absolute;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  font-weight: 700;

  padding: 0 6.3205px 4px;
  transform: translate(-6.3205px, 7px);
}

/* Scroll bar list place */
.vertical_side_panel::-webkit-scrollbar {
  display: none;
}

/* for firefox scroll bar */
.vertical_side_panel {
  scrollbar-width: none;
}

@media only screen and (max-width: 360px) {
  .vertical_side_panel {
    width: calc(100% - 40px);
  }
}
