.subsystem_container {
    background-color: #f5f5f5;
    position: sticky;
    position: -webkit-sticky;
    top: -35px;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
}

.search_subsystem_layout {
    padding: 45px 40px;
    width: clamp(50px, calc(100% - 80px),  520px);
    margin: 0 auto;
}


.search_input {
    padding-left: 32px !important;
}

.search_style {
    position: absolute;
    margin-left: 5px;
    margin-top: 14px;
}

.rotate {
    transform: rotate(90deg);
    color: rgb(245, 28, 87);
}

.animation {
    transform: rotate(-90deg);
}

.select_place {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 609px) {
    .subsystem_container {
        position: unset;
        top: unset;
    }
}