.header {
  position: absolute;
  top: 0px;
  z-index: 3;
  background-color: white;

  min-height: 40px;
  max-height: 40px;
  min-width: calc(100% - 100px);

  padding: 15px 50px;
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app_logo {
  flex: 0 1 auto;
  aspect-ratio: 320 / 29;
  max-height: 40px;

  cursor: pointer;
}

.epsa_logo {
  flex: 0 1 227.75px;
  max-height: 60px;
  height: 60px;
}

.epsa_logo_normal {
  height: 60px;
}

.epsa_logo_absolute {
  height: 80px;
  position: absolute;
  transform: translate(calc((227.75px - 150.08px) / 2), -5px);
}

@media only screen and (max-width: 600px) {
  .epsa_logo_absolute {
    right: 0px;
    transform: translate(0, -5px);
  }
}

@media only screen and (max-width: 575px) {
  .header {
    min-height: 80px;
    max-height: 80px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
  }

  .epsa_logo_absolute {
    right: unset;
    transform: translate(calc((227.75px - 150.08px) / 2), -5px);
  }
}
