.search_subsystem_layout {
  padding: 45px 40px;
}

.search_style {
  position: absolute;
  margin-left: 5px;
  margin-top: 14px;
}

.search_input {
  padding-left: 32px !important;
}

.rotate {
  transform: rotate(90deg);
  color: rgb(245, 28, 87);
}

.animation {
  transform: rotate(-90deg);
}

.select_place {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
