.wrapper > div:first-child {
  width: 100%;
  height: calc(100vh - 100px);
}

.wrapper_full > div:first-child {
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 575px) {
  .wrapper > div:first-child {
    height: calc(100vh - 140px);
  }
}
