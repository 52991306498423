body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Commissioner";
  src: local("static"),
    url("./assets/fonts/static/Commissioner-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Commissioner";
  src: local("static"),
    url("./assets/fonts/static/Commissioner-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Commissioner";
  src: local("static"),
    url("./assets/fonts/static/Commissioner-Black.ttf") format("truetype");
  font-weight: 900;
}
