.centerMapBtn {
  position: absolute;
  left: calc(50% - 30px);
  z-index: 1;
  top: 0;

  width: 60px;
  height: 30px;

  border: 1px solid black;

  background-image: url("./../../assets/icons/other/eye.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
}

.marker_modal {
  width: calc(100% - 20px);
  border-radius: 6px;
  padding: 10px 10px 20px;
  height: 100%;
  font-size: 12px;
  background-color: rgb(104, 104, 104);

  color: white;
}

.marker_modal > div {
  margin: 10px 0;
  min-height: 2px;
  border-radius: 6px;
  background-color: white;
}

@media only screen and (max-width: 609px) {
  .centerMapBtn {
    left: calc(75% - 15px);
  }
}
