.weatherTitle {
  position: absolute;
  top: 24px;
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  min-height: 32px;
  /* color: white; */

  margin-bottom: 20px;
  transform: translateY(-20px);
  z-index: 1;

  background-color: rgb(131, 56, 236);
  color: white;
}

.weatherGridContainer {
  height: 208px;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 50% 50%;
  color: white;
}
.squareBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.squareBox:nth-child(1) {
  border: 1px solid white;
  border-top: hidden;
  border-left: hidden;
}

.squareBox:nth-child(2) {
  border-bottom: 1px solid white;
}

.squareBox:nth-child(3) {
  border-right: 1px solid white;
}

.descriptionContent {
  position: absolute;

  width: 120px;
  bottom: 5px;
  left: 0;
  font-size: 12px;
  text-align: center;
}

.tempBox {
  font-size: 32px;
  font-weight: 700;
}
