.horizontal_side_panel {
  position: absolute;
  width: clamp(250px, 25%, 400px);

  /* max-width: 400px; */
  /* width: 25%; */

  height: 100%;

  background-color: rgb(245, 245, 245);
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);

  transition: transform 0.15s ease-out;
  transform: translateX(-100%);
  z-index: 2;
}

.side_panel_width {
  transition: transform 0.25s ease-in;
  transform: translateX(0%);
}

.expand_button {
  position: absolute;
  top: calc(50% - 26px);
  left: 100%;
  width: 26px;
  height: 52px;
  color: white;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-radius: 0px 12px 12px 0px;
  background-color: rgb(0, 0, 0, 0.9);
  box-shadow: 1px 1px 4px 1px #888888;

  cursor: pointer;
}

.content_panel {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

@media only screen and (max-width: 609px) {
  .horizontal_side_panel {
    width: 100%;

    height: 90%;
    transition: transform 0.25s ease-out;
    transform: translateY(-100%);
  }

  .side_panel_width {
    transition: transform 0.35s ease-in;
    transform: translateY(0%);
  }

  .expand_button {
    top: 100%;
    /* transform: rotate(90deg); */

    left: calc(50% - 13px);
    transform: translate(0%, -14px) rotate(90deg);
  }
}

/* Scroll bar list place */
.content_panel::-webkit-scrollbar {
  display: none;
}

/* for firefox scroll bar */
.content_panel {
  scrollbar-width: none;
}

.hover_expand {
  width: 26px;
  overflow: hidden;
  will-change: width;
  transition: width 0.3s ease-in-out 0.1s;
}

.expand_button span {
  display: block;
  font-size: 20px;
  text-align: left;
  will-change: transform;
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(-120px, -3px);
}

.hover_expand {
  overflow: hidden;
  text-align: right;
}

.hover_expand span:before {
  position: absolute;
  content: var(--contentButton);
}

@media only screen and (min-width: 610px) {
  @media (hover: hover) {
    .hover_expand:hover {
      width: 150px;
    }

    .hover_expand:hover span {
      transform: translate(0, -3px);
      transition: transform 0.3s ease-in-out 0.1s;
    }
  }
}
