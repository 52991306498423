.welcome_placeholder {
  padding: 30px 20px;
  font-weight: 700;
  color: white;
  line-height: 1.5;
}

@media only screen and (max-width: 991px) {
  .welcome_placeholder {
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .welcome_placeholder {
    padding: 20px 20px 10px;
  }
}
