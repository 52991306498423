.entity {
  min-height: 38px;
  max-height: 38px;
  min-width: 100%;
  max-width: 100%;

  /* background-color: white; */
  margin: 8px 0px;
  font-size: 14px;
  line-height: 17px;

  /* border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0.7) 20%,
    rgba(255, 255, 255, 0) 90%
  ); */
}

.gridContainer {
  display: grid;

  grid-template-columns: 32px calc(100% - 108px) 75px;
  grid-template-rows: 38px;
  grid-template-areas: "icon content value";
}

.icon {
  grid-area: icon;
  margin: auto 0px;
}

.content {
  grid-area: content;
  overflow: hidden;
  margin: auto 0;
  padding: 0px 8px;
  max-height: 38px;
}

.value {
  grid-area: value;
  font-weight: 700;

  text-align: end;
  margin: auto 0;
}
