.slick-slider .slick-arrow {
  top: 100%;
  z-index: 1;
  margin-top: 10px;
  width: 50px;
  height: 50px;
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  font-size: 30px;
  color: black;
  font-weight: 800;
}

.slick-slider .slick-prev::before {
  /* content: "\276E"; */
  content: url("./../../assets/icons/other/play-triangle-outline-left.png");
}

.slick-slider .slick-next::before {
  /* content: "\276F"; */
  content: url("./../../assets/icons/other/play-triangle-outline.png");
}

.slick-slider .slick-next {
  right: 30%;
}

.slick-slider .slick-prev {
  left: 30%;
}
