.box_placeholder {
  margin: 30px 0;

  max-height: calc(100vh - 160px);
  overflow-y: auto;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.medium {
  max-height: 243px;
  min-height: 243px;
  overflow-y: hidden;
  /* background-color: rgb(251, 86, 6); */
}

.large {
  max-height: 528px;
  min-height: 528px;
  overflow-y: hidden;
  /* background-color: rgb(51, 213, 83); */
}

.map_box {
  max-height: 539px;
  min-height: 539px;
  max-width: 270px !important;
  min-width: 270px !important;
  overflow-y: hidden;
  padding: 16px 5px 5px !important;
}
/* Scroll bar style */

.box_placeholder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.box_placeholder::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.box_placeholder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(92, 99, 106);
}

.box_placeholder::-webkit-scrollbar-thumb:hover {
  background-color: #373b40;
}

/* for firefox scroll bar */
.box_placeholder {
  scrollbar-color: #373b40 #f5f5f5;
  scrollbar-width: thin;
}

.weatherBox {
  background-color: rgb(131, 56, 236);
}

.frameBox {
  background-color: #8f4433;
}

.diav {
  background-color: #9a3122;
}

.gather_rubish {
  background-color: #18d786 !important;
}

.electric {
  background-color: #ffe318;
}

.nav {
  background-color: #6e93ff;
}
.uv_style {
  background-color: #7698a7 !important;
}

@media only screen and (max-width: 991px) {
  .box_placeholder {
    max-height: calc(100vh - 268px);
    padding: 0 calc((100% - 876px) / 2);
  }
}
@media only screen and (max-width: 875px) {
  .box_placeholder {
    max-width: 587px;
    padding: 0 calc((100% - 587px) / 2);
  }
}

@media only screen and (max-width: 585px) {
  .box_placeholder {
    margin-top: 10px;
    max-width: 300px;
    max-height: calc(100vh - 248px);
    padding: 0 calc((100% - 300px) / 2);
  }
}

@media only screen and (max-width: 575px) {
  .box_placeholder {
    max-height: calc(100vh - 258px);
  }
}

@media only screen and (max-width: 544px) {
  .box_placeholder {
    max-height: calc(100vh - 282px);
  }
}

@media only screen and (max-width: 506px) {
  .box_placeholder {
    max-height: calc(100vh - 306px);
  }
}
