.chart_box {
  position: relative;
  padding: 5px;
  background-color: black;
  border-radius: 50%;
}

/*.chart_box > span {*/
/*  position: absolute;*/
/*  color: black;*/
/*  font-size: 14px;*/
/*  margin-left: 15px;*/
/*  top: -20px;*/
/*}*/

.chart_style {
  /*margin: 20px 0;*/
  padding: 5px;
  background-color: white;
  /*border-radius: 24px;*/
  border-radius: 50%;
}

.chart_section h2 {
  font-size: 16px;
  line-height: 1.4;
  margin: 20px 0 10px;
}

.chart_loadingStyle {
  opacity: 0.2;
}
