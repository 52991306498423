.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  min-width: 100%;
  max-width: 100%;
  transform: unset !important;
  opacity: unset !important;
}

.title {
  font-weight: 700;
  padding: 0px 17px;
  text-align: center;
}

.title a {
  text-decoration: none;
  color: black;
}

.info_icon {
  transform: translate(0px, 2px);
}

.triggerTooltip:hover :nth-child(2) {
  transition: padding 0s linear, max-height 0.25s ease-in;
  transition-delay: 0.25s;
  padding: 10px;
  max-height: 500px;
}

.lastElementPlaceholder {
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragAndDropTooltip {
  min-width: 105px;
  transform: translate(-47px, 37px);
}

.triggerTooltip:hover :nth-child(2).dragAndDropTooltip {
  transition: padding 0s linear, max-height 0.25s ease-in;
  transition-delay: 0.5s;
  padding: 10px;
  max-height: 500px;
}
.triggerTooltip:active :nth-child(2).dragAndDropTooltip {
  display: none;
}
