.wrapper > div:first-child {
  width: 100%;
  height: 489px;
}

.map_title_style {
  padding: 0 15px;
  max-width: calc(100% - 30px);
  min-width: calc(100% - 30px);
}

.wrapper_half > div:first-child {
  width: 100%;
  height: 289px;
}
.form_selection,
.selection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav_options {
  margin: 20px 0 10px;
  font-size: 23px;
  font-weight: 700;
  line-height: 25px;
}

.selection > select {
  margin: 5px 0;
  width: 90%;
  text-align-last: center;
  height: 35px;

  border-radius: 6px;
  border: none;
  outline: none;

  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.form_selection > button {
  margin-top: 10px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid black;
  background-color: black;
  color: white;

  font-size: 17px;
  width: 90%;
  cursor: pointer;
}
