.info_container {
  padding-bottom: 15px;
}
.sticky_title {
  position: sticky;
  position: -webkit-sticky; /* for safari */
  padding-top: 10px;
  top: 0;
  background-color: rgb(104, 104, 104);

  padding-bottom: 0.1px;
  margin-bottom: 20px;
  z-index: 1;
}
.info_title a {
  color: white;
}

.gradient_border {
  border-bottom: 1px solid rgb(121, 121, 121);
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    rgb(121, 121, 121) 0%,
    rgb(121, 121, 121) 90%,
    rgba(121, 121, 121, 0) 90.01%
  );
}

/* .gradient_border {
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0.7) 20%,
    rgba(255, 255, 255, 0) 90%
  );
} */
/* .gradient_border > div:nth-child(2) {
  border-right: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 20%,
    rgba(255, 255, 255, 0) 90%
  );
} */

.gradient_line_style {
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 95%
  );
}

.height_unset {
  max-height: unset;
}

.more_photos_descr {
  font-size: 14px;
  margin: 20px 0 5px;
}

.slider_placeholder [class~="slick-track"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_placeholder [class~="slick-arrow"] {
  top: 50%;
  margin-top: 0;
  background-color: rgba(104, 104, 104, 0.75);
}

.slider_placeholder [class~="slick-prev"] {
  left: 0;
}

.slider_placeholder [class~="slick-next"] {
  right: 0;
}
