.subTitle_placeholder {
  min-width: 100%;
  max-width: 100%;
  min-height: 38px;
  max-height: 38px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  text-align: center;
}

.subTitleBold {
  font-weight: 700;
}
