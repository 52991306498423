.iframes_container {
  padding: 0;
}
.iframes_container > li {
  min-height: 85.6px;
  max-height: 85.6px;
  margin: 5px 0;

  list-style: none;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iframes_container > li > p {
  padding: 5px;
}

.iframes_smaller_imgs > li {
  min-height: 59.6px;
  max-height: 59.6px;
}
