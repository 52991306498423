.entity_container {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0 30px;
}

.title_container {
  display: flex;
  align-items: center;
}
.entity_title {
  font-size: 14px;
  font-weight: 700;
}
.entity_addres {
  font-size: 12px;
}
.information {
  padding-left: 29px;
}
.information p {
  margin: 5px 0;
}

.information img {
  margin-right: 5px;
}
