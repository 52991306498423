.footer {
  min-width: 100%;
  min-height: 30px;
  max-height: 30px;

  display: flex;
  align-items: center;
}

.copyright {
  margin-left: 20px;
}
