.subsystem_layout {
}

.subsystem_title_style {
  max-height: 77px;
  min-height: 77px;
  background-color: rgb(60, 204, 82);
  width: calc(100% - 40px);
  padding: 20px;

  text-align: center;
}

.flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.subsystem_title {
  font-weight: 500;
  font-size: 19px;
}

.list_place {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 160px;
  max-height: calc(100vh - 410px);
}
.moreSpace {
  min-height: 160px;
  max-height: calc(100vh - 460px);
}

.list_place ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list_place li {
  min-height: 104.297px;
  cursor: pointer;
}
.list_place .active:hover,
.active {
  background-color: rgb(220, 220, 220);
}

.list_place li:hover {
  background-color: rgb(230, 230, 230);
}

.list_place a {
  text-decoration: none;
  color: black;
}

.susystem_item {
  width: 100%;
  height: 100%;
}

/* Scroll bar list place */
.list_place::-webkit-scrollbar {
  display: none;
}

/* for firefox scroll bar */
.list_place {
  scrollbar-width: none;
}

.nav_badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: black;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_badge > span {
  padding-bottom: 2.5px;
}
.sticky_nav {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 117px;
  z-index: 1;
}

@media only screen and (max-width: 1251px) {
  .list_place {
    max-height: calc(100vh - 446px);
  }
}

@media only screen and (max-width: 609px) {
  .subsystem_title_style {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .list_place {
    max-height: unset;
  }
}

@media only screen and (max-height: 650px) {
  .subsystem_title_style {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
  }
  .list_place {
    max-height: unset;
  }
}
